:root {
  /* The value of --vh will be updated via JavaScript */
  --vh: 1vh;
}

body {
  font-family: "gill-sans-nova", Arial, sans-serif;
}

html {
  scroll-behavior: smooth;
  color-scheme: light;
}

.none {
  display: none !important;
}

a {
  text-decoration: none;
  color: inherit;

  &.link {
    text-decoration: underline;
  }
}

.clearfix {
  clear: both;
}

div.call-to-action {
  display: flex;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  a {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding-bottom: 4px;
    color: #ffffff;
    background-color: #86c0b4;

    font-size: 18px;
    font-weight: 400;
    line-height: 20px;

    width: 375px;
    height: 60px;
    border-radius: 30px;
    border: none;

    cursor: pointer;

    > h3 {
      font-size: 22px;
      color: #ffffff;
      text-align: center;
    }

    .icon {
      height: 28px;
    }

    &:hover {
      background-color: rgb(82, 112, 107);
    }
  }
}

.App {
  position: relative;

  .partner-container {
    position: absolute;
    background-color: #e34d3c;
    width: 100%;
    height: 200px;
    bottom: -80px;
    z-index: 4;

    .designed-by {
      padding: 10px;
      text-align: center;

      img {
        max-height: 38px;
      }
    }

    .partners {
      max-width: 940px;
      margin: 10px auto;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 25px;

      .partner-logos-container {
        display: flex;
        justify-content: center;
        gap: 40px;

        &.mobile {
          display: none;
        }
        &.desktop {
          display: flex;
        }

        &.small {
          justify-content: center;
          gap: 40px;
        }
        .partner-logo-container {
          .partner-logo {
            height: 50px;
          }
        }
      }

      .copyright-container {
        display: flex;
        justify-content: space-between;

        .link-collection {
          display: flex;
          justify-content: space-between;
          gap: 12px;
        }
      }
    }
  }
}

.header {
  height: 98px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  transition: height 0.3s ease;

  .menu-container {
    transition: height 0.3s ease;
  }

  .logo-container {
    transition: top 0.3s ease;

    .injected-svg {
      transition: width 0.3s ease;
    }
  }

  &.smaller {
    height: 60px;

    .menu-container {
      height: 60px;
    }

    .logo-container {
      top: 20px;
      max-height: 60px;

      > a {
        max-height: 60px;
      }

      .injected-svg {
        width: 60px;
        max-height: 60px;
      }
    }
  }
}

.header-container {
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .slogan {
    position: relative;
    top: 16px;
    color: #52706b;
    font-size: 16px;
    padding: 4px 14px;
    border: 1px dashed #526a6b;
    transform: rotate(4deg);
    border-radius: 12px;
    font-weight: lighter;
    text-align: center;
    height: fit-content;

    .bold {
      font-weight: bolder;
    }

    .head {
      font-weight: bold;
      font-size: 18px;
      display: block;
      margin: 0;
    }

    .text-0 {
      line-height: 14px;
      margin-bottom: 4px;
    }

    .text-1 {
      line-height: 20px;
    }
  }

  .logo-container {
    position: relative;
    top: 26px;
    max-width: 140px;
    max-height: 98px;
  }

  .menu-container {
    height: 98px;
    gap: 30px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .menu-item {
      color: #4d4044;
      border-bottom: 3px solid rgba(255, 255, 255, 0);

      font-family: KoHo;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;

      height: 100%;
      display: flex;
      align-items: center;

      &.active,
      &:hover {
        color: #e34d3c;
        border-bottom: 3px solid #e34d3c;
      }
    }
  }
}

.Tour-main,
.Impressum-main,
.Partners-main,
.DataSecurity-main {
  max-width: 940px;
  margin: 0 auto;

  a {
    color: #e34d3c;
  }
}

.Join-main,
.Newsletter-main {
  max-width: 940px;
  margin: 0 auto;

  a {
    color: #e34d3c;
  }
}

.Vision-main,
.Join-main {
  position: relative;
  overflow-x: clip;
  max-height: 2400px;

  .main-content-headline {
    h1 {
      margin-bottom: 10px;
      margin-top: 50px;
      text-align: center;
      text-transform: uppercase;
      font-family: KoHo;
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #53716c;
    }
  }

  .main-content-circle {
    position: absolute;
    z-index: 1;
    top: 1040px;
    left: 50%;
    transform: translateX(-50%);
    height: 400px;
    width: 400px;
    background-color: #87c1b4;
    border-radius: 100%;
    border: solid 20px white;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-content-circle-text {
      padding: 30px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }

  .main-content-circle-2 {
    position: absolute;
    z-index: 1;
    top: 1790px;
    left: calc(50% - 150px);
    transform: translateX(-50%);
    height: 400px;
    width: 400px;
    background-color: white;
    border-radius: 100%;
    border: solid 20px #009edb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-content-circle-text {
      padding: 30px;
      padding-bottom: 0px;
      padding-top: 0px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }

    > img {
      max-width: 95%;
      margin: 15px 0;
    }
  }

  .main-content-text-right {
    position: absolute;
    z-index: 1;
    top: 1915px;
    left: calc(50% - 120px);
    transform: translateX(50%);
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    .main-content-text-right-text {
      padding: 15px;
      padding-bottom: 0px;
      padding-top: 20px;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #e34d3c;
      text-align: center;
    }

    .main-content-text-right-subtext {
      color: black;
    }
  }

  .main-content-line {
    background-image: url("../assets/LineVision.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 0;
    top: 620px;
    left: 50%;
    transform: translateX(-50%);
    height: 900px;
    max-width: 900px;
    width: 100%;
  }

  .main-content-line-2 {
    background-image: url("../assets/LineVision2.svg");
    background-repeat: no-repeat;
    background-size: 242px;
    position: absolute;
    z-index: 0;
    top: 1513px;
    left: calc(50% + 5px);
    transform: translateX(-50%);
    height: 900px;
    max-width: 900px;
    width: 100%;
  }

  .main-content-line-3 {
    background-image: url("../assets/LineVision3.svg");
    background-repeat: no-repeat;
    background-size: 585px;
    position: absolute;
    z-index: 0;
    top: 2192px;
    left: calc(50% + 350px);
    transform: translateX(-50%);
    height: 900px;
    max-width: 900px;
    width: 100%;
  }

  .slideshow-area {
    transform: scale(1.6);
    transform-origin: center top;
    max-width: 940px;
    margin: 0 auto;

    display: flex;
    justify-content: center;

    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .main-image {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: flex-start;
    max-height: 750px;
    overflow: hidden;

    max-width: 1750px;
    margin: 0 auto;
    margin-top: 450px;

    > img {
      width: 100%;
    }
  }

  .main-image-map {
    width: 100%;
    min-width: 650px;
    display: flex;
    justify-items: center;
    align-items: flex-start;
    max-height: 900px;
    overflow-x: clip;
    overflow-y: visible;

    max-width: 1750px;
    margin: 0 auto;
    margin-top: 200px;

    > img {
      width: 115%;
    }
  }
}

.Team-main,
.Sponsors-main {
  .main-image {
    max-width: 100%;
  }
}

.Team-main {
  .main-image {
    z-index: 1;
    position: relative;
  }

  .main-container {
    overflow: visible !important;
  }
}

.main {
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 160px;
  margin-top: 178px;
  min-height: 500px;

  &.is-tour {
    margin-top: 98px;
    margin-bottom: 60px;
    min-height: calc(var(--vh) * 100);
    max-height: 4500px;

    .main-container {
    }
  }

  &.is-home {
    margin-bottom: 0px;
    background-color: #cef2eb;
    margin-top: 98px;
    min-height: 4000px;
    background-image: url("../assets/map-background-long.png");
    background-size: 1720px;
    background-position: calc(50%);
    background-position-y: -380px;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &.small {
      .sub-headline,
      .main-headline,
      .un-headline {
        opacity: 0 !important;
      }
    }

    &.smaller {
      .main-container {
        .home-container {
          .content-grid {
            opacity: 1;
          }
        }
      }
    }
  }

  .route-container {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    &.is-home {
      background-image: url("../assets/Route.svg");
      background-size: 653px;
      background-position: calc(50%);
      background-position-y: 45px;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }

    &.is-blog {
      background: none;
    }
  }

  .main-container {
    height: 100%;

    .tour-route-container {
      background-color: #cef2eb;
      height: calc(var(--vh) * 100 - 98px);
      position: relative;
      overflow: hidden;

      &.etappe-0 {
        height: 5000px;
      }

      .info-container {
        right: -200vw;
        display: flex;
        position: absolute;
        width: 50vw;
        margin-top: 10px;

        top: 0px;
        z-index: 0;
        bottom: 60px;
        flex-direction: column;
        border-radius: 10px;
        justify-content: start;
        align-items: center;
        transition: 0.5s right 0.1s ease;
        z-index: 10;

        .info-button-container {
          margin-bottom: 10px;
          padding-top: 5px;
          height: 40px;
          width: 100%;
          display: flex;
          gap: 20px;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20px;

          .sign-up-button,
          .komoot-button {
            a {
              display: flex;
              justify-content: center;
              gap: 10px;
              align-items: center;
              color: white;
              background-color: rgba(235, 138, 116, 1);
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              width: 260px;
              height: 36px;
              border-radius: 30px;
              border: none;
              cursor: pointer;

              .icon {
                width: 24px;
                height: 24px;

                svg {
                  height: 24px;
                  width: 24px;

                  > circle,
                  > path {
                    stroke: white;
                  }
                }
              }
            }

            &.content {
              margin-bottom: 20px;
            }
          }

          .komoot-button {
            a {
              width: 220px;
              background-color: rgba(79, 132, 13, 1);
            }
          }

          .close-icon {
            width: 48px;
            height: 48px;
            cursor: pointer;

            svg {
              width: 48px;
              height: 48px;

              > circle,
              > path {
                stroke: white;
                stroke-width: 1px;
              }
            }
          }
        }

        &.visible {
          right: 15px;
          transition: 0.5s right 0.1s ease;
        }

        .info-scroll-container {
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100%;
          gap: 40px;
          overflow-y: auto;
          overflow-x: hidden;
          flex-direction: column;

          .poi-container {
            width: 100%;
            padding: 0;
            z-index: 5;
            display: flex;
            flex-direction: column;
            gap: 40px;

            .poi-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              background-color: #ffffff;
              border-radius: 20px;
              padding: 15px 5px;

              > div {
                z-index: 10;
                position: relative;
              }

              .info-etappe-image-slider {
                padding: 0px;
                height: calc(0.5 * var(--vh) * 100);
                padding: 30px 0px;
                padding-top: 10px;

                width: 100%;

                > div {
                  overflow: visible !important;
                }
                .rtds-single-slide-styles {
                  height: 100% !important;

                  > div {
                    padding: 0 !important;
                    position: relative;
                  }

                  img {
                    max-height: initial;
                    height: 100%;
                    border: solid 1px black;
                  }
                }

                .image-container {
                  position: relative;
                  height: calc(100% - 20px);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 20px;
                }

                .caption-text,
                .footnote-text {
                  text-align: center;
                  position: absolute;
                  width: fit-content;
                }

                .caption-text {
                  bottom: -2px;
                  font-size: 20px;
                  line-height: 20px;
                  margin-left: auto;
                  margin-right: auto;
                  left: 5px;
                  right: 5px;
                  color: black;
                  padding: 5px 10px;
                  padding-bottom: 8px;
                }

                .footnote-text {
                  white-space: nowrap;
                  bottom: -20px;
                  font-family: "gill-sans-nova";
                  font-size: 12px;
                  line-height: 16px;
                  padding: 2px 8px;
                  color: #888888;
                }
              }
              .info-etappe-image-slider-indicator {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 10px 0;
                margin-top: 0;
                gap: 10px;

                .indicator-container {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: center;
                  align-items: center;

                  .dots {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 5px 0;
                    gap: 6px;

                    .dot {
                      cursor: pointer;
                      width: 12px;
                      height: 12px;
                      background-color: grey;
                      border-radius: 100%;

                      &.active {
                        background-color: rgba(227, 77, 60, 1);
                      }
                    }
                  }
                }
              }

              .info-etappe-poi-title {
                display: flex;
                align-items: center;
                gap: 15px;
                width: 100%;
                margin: 10px 0;
                font-family: KoHo;
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;

                &.is-start,
                &.is-end {
                  .icon {
                    padding: 4px;
                    border: solid 3px #e34d3c;
                  }
                }

                &.is-offshore {
                  .icon {
                    border-width: 10px;
                    width: 32px;
                    height: 32px;

                    svg {
                      width: 32px;
                      height: 32px;
                    }
                  }
                }

                .icon {
                  width: 40px;
                  height: 40px;
                  margin-left: 14px;
                  border-radius: 100%;
                  background-color: #87c0b4;
                  justify-content: center;
                  align-items: center;
                  display: flex;

                  svg {
                    width: 40px;
                    height: 40px;
                  }
                }
                svg {
                  display: block;
                }

                .text {
                  flex: 1;
                  padding-right: 10px;
                  font-size: 38px;
                  color: rgba(83, 112, 107, 1);
                }
              }

              .info-etappe-poi-text {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 20px;
                font-weight: 400;
                color: black;
                line-height: 22x;
                margin-bottom: 20px;
              }

              hr {
                margin: 0 15px;
                margin-bottom: 120px;
                border-color: #87c0b4;
              }
            }
          }

          > div {
            padding: 0px 10px;
            z-index: 10;
          }

          .info-button-container {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            padding: 0;
          }

          .info-etappe-container {
            width: 500px;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: #52706b;
            padding: 10px 20px;
            margin-top: 25px;

            .info-etappe-name {
              display: flex;
              justify-content: space-between;
              border-bottom: dashed 1px rgba(134, 192, 180, 1);
              padding-bottom: 10px;

              .text,
              .time {
                padding-top: 10px;
                color: white;
                font-size: 22px;
                font-weight: 400;
                line-height: 31.55px;
                text-align: center;
              }

              .text {
                font-weight: 600;
              }

              .close-icon {
                position: absolute;
                transform: scale(0.8);
                right: 4px;
                top: 4px;
                cursor: pointer;

                svg {
                  path,
                  circle {
                    stroke: rgba(235, 138, 116, 1);
                  }
                }
              }
            }

            .info-etappe-title {
              color: rgb(134, 192, 180);
              font-family: KoHo;
              font-size: 36px;
              font-weight: 500;
              line-height: 46.8px;
              text-align: center;
            }

            .info-etappe-details {
              padding-bottom: 10px;

              color: rgba(134, 192, 180, 1);
              font-size: 22px;
              font-weight: 400;
              line-height: 16px;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;

              .content-left-info-left,
              .content-left-info-right {
                display: flex;
                gap: 10px;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                > div > div {
                  svg {
                    transform: scale(0.8);
                  }
                }
              }
            }
          }
        }
      }

      .back-container {
        position: absolute;
        display: flex;
        top: 15px;
        left: 10px;
        z-index: 10;

        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        color: #52706b;
        background-color: #ffffff;
        border: solid 1px #52706b;

        font-size: 20px;
        font-weight: 400;
        line-height: 24px;

        width: 180px;
        height: 36px;
        border-radius: 30px;

        cursor: pointer;

        .icon {
          height: 24px;
          width: 24px;

          svg {
            height: 24px;
            width: 24px;
          }
        }

        &:hover {
          background-color: #dddddd;
        }
      }

      .options-container {
        justify-content: center;
        align-items: center;
        background-color: #52706b;
        border-radius: 30px;
        padding: 0 10px;
        margin: 10px;
        margin-top: 15px;
        margin-right: 0;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 13;
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: 38px;
        cursor: pointer;
        transition: right 0.1s ease;

        &.open {
          right: calc(50vw - 160px);
        }

        .options-checkbox {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #f1f3f2;

          .icon {
            display: none;

            div,
            svg {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          &.active {
            .icon {
              display: inherit;
            }
          }
        }

        .options-label {
          color: white;
          white-space: nowrap;
          margin-bottom: 1px;
        }
      }

      .route-line,
      .map-big {
        width: 2865px;
        position: absolute;
        height: 100%;

        > div:first-child {
          z-index: 3;
          position: absolute;

          svg {
            height: calc(var(--vh) * 100 - 98px);
          }
        }

        > div.overlay-layer {
          z-index: 2;
          position: absolute;
          width: 1200vw;
          height: 400%;
          background-color: rgba(0, 0, 0, 0.7);
          left: 300vw;
          top: -200%;
          transition: 0.5s left 0.5s ease;

          &.open {
            left: -200vw;
          }
        }

        > div:nth-child(3) {
          z-index: 1;
          position: absolute;

          svg {
            height: calc(var(--vh) * 100 - 98px);
          }
        }

        > div:nth-child(3) {
          > div {
            background-color: #cef2eb;
          }
        }

        > div:not(.etappe-container) {
          > div {
            > svg {
              opacity: 0;
              overflow: visible;
            }
          }
        }

        > div.etappe-container {
          &.open {
            .start,
            .end {
              .name {
                color: white;
              }

              .poi {
                .poi-name {
                  color: white;
                }
              }
            }

            .stop {
              .name {
                color: white;
              }
            }

            .pois {
              .name {
                color: grey;
              }
            }
          }
        }

        &.etappe-0 {
          left: calc(-600px + (100vw - 1734px) * 0.4);
          transform: scale(1);
          > div > div > svg {
            height: 5000px;
          }
        }

        &.etappe-1 {
          left: calc(
            -300px + (var(--vh) * 100 - 793px) * 0.3 + (100vw - 1734px) * 0.55
          );
          transform: scale(var(--scale-factor-etappe-1));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -750px + (var(--vh) * 100 - 793px) * 0.15 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-1-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-2 {
          left: calc(
            -225px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.63
          );
          transform: scale(var(--scale-factor-etappe-2));
          transition: transform 0.5s ease, left 0.5s ease;
          &.open {
            left: calc(
              -800px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-2-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-3 {
          left: calc(
            -350px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.605
          );
          transform: scale(var(--scale-factor-etappe-3));
          transition: transform 0.5s ease, left 0.5s ease;
          &.open {
            left: calc(
              -900px + (var(--vh) * 100 - 793px) * 0.15 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-3-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-4 {
          left: calc(
            -300px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.6
          );
          transform: scale(var(--scale-factor-etappe-4));
          transition: transform 0.5s ease, left 0.5s ease;
          &.open {
            left: calc(
              -800px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-4-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-5 {
          left: calc(
            -300px + (var(--vh) * 100 - 793px) * 0.3 + (100vw - 1734px) * 0.615
          );
          transform: scale(var(--scale-factor-etappe-5));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -800px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-5-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-6 {
          left: calc(
            -300px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.635
          );
          transform: scale(var(--scale-factor-etappe-6));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -800px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-6-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-7 {
          left: calc(
            -100px + (var(--vh) * 100 - 793px) * 0.5 + (100vw - 1734px) * 0.65
          );
          transform: scale(var(--scale-factor-etappe-7));
          transition: transform 0.5s ease, left 0.5s ease;
          &.open {
            left: calc(
              -650px + (var(--vh) * 100 - 793px) * 0.3 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-7-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-8 {
          left: calc(
            -100px + (var(--vh) * 100 - 793px) * 0.5 + (100vw - 1734px) * 0.62
          );
          transform: scale(var(--scale-factor-etappe-8));
          transition: transform 0.5s ease, left 0.5s ease;
          &.open {
            left: calc(
              -550px + (var(--vh) * 100 - 793px) * 0.2 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-8-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-9 {
          left: calc(
            -300px + (var(--vh) * 100 - 793px) * 0.3 + (100vw - 1734px) * 0.55
          );
          transform: scale(var(--scale-factor-etappe-9));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -800px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.3
            );
            transform: scale(var(--scale-factor-etappe-9-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-10 {
          left: calc(
            -500px + (var(--vh) * 100 - 793px) * 0.05 + (100vw - 1734px) * 0.525
          );
          transform: scale(var(--scale-factor-etappe-10));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1000px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-10-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-11 {
          left: calc(
            -650px + (var(--vh) * 100 - 793px) * -0.1 + (100vw - 1734px) * 0.465
          );
          transform: scale(var(--scale-factor-etappe-11));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1100px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-11-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-12 {
          left: calc(
            -550px + (var(--vh) * 100 - 793px) * 0.25 + (100vw - 1734px) * 0.5
          );
          transform: scale(var(--scale-factor-etappe-12));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1000px + (var(--vh) * 100 - 793px) * 0.15 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-12-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-13 {
          left: calc(
            -625px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.47
          );
          transform: scale(var(--scale-factor-etappe-13));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1050px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-13-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-14 {
          left: calc(
            -700px + (var(--vh) * 100 - 793px) * 0.1 + (100vw - 1734px) * 0.45
          );
          transform: scale(var(--scale-factor-etappe-14));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1150px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-14-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-15 {
          left: calc(
            -625px + (var(--vh) * 100 - 793px) * -0.25 + (100vw - 1734px) * 0.46
          );
          transform: scale(var(--scale-factor-etappe-15));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1100px + (var(--vh) * 100 - 793px) * -0.025 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-15-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-16 {
          left: calc(
            -600px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.485
          );
          transform: scale(var(--scale-factor-etappe-16));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1050px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-16-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        &.etappe-17 {
          left: calc(
            -700px + (var(--vh) * 100 - 793px) * -0.5 + (100vw - 1734px) * 0.44
          );
          transform: scale(var(--scale-factor-etappe-17));
          transition: transform 0.5s ease, left 0.5s ease;

          &.open {
            left: calc(
              -1200px + (var(--vh) * 100 - 793px) * 0 + (100vw - 1734px) * 0.2
            );
            transform: scale(var(--scale-factor-etappe-16-open));
            transition: transform 0.5s ease, left 0.5s ease;
          }
        }

        .etappe-0,
        .etappe-1,
        .etappe-2,
        .etappe-3,
        .etappe-4,
        .etappe-5,
        .etappe-6,
        .etappe-7,
        .etappe-8,
        .etappe-9,
        .etappe-10,
        .etappe-11,
        .etappe-12,
        .etappe-13,
        .etappe-14,
        .etappe-15,
        .etappe-16,
        .etappe-17 {
          position: absolute;
          z-index: 4;
          height: calc(var(--vh) * 100 - 98px);
          transform-origin: top left;
          opacity: 0;

          &.visible {
            opacity: 1;
            transition: opacity 0.5s ease;
          }

          .stage {
            cursor: pointer;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-direction: row-reverse;

            &.is-right {
              flex-direction: row;
            }

            &.stage-1 {
              .icon-number {
                background-color: #e34d3c;
                color: #cef2eb;
              }
            }

            &.stage-18 {
              .icon-number {
                color: #e34d3c;
                background-color: #e34d3c;
              }
            }

            .icon-number {
              font-size: 22px;
              font-weight: 600;
              width: 34px;
              border-radius: 100px;
              border: solid 4px #e34d3c;
              background-color: #cef2eb;
              height: 34px;
              line-height: 34px;
              text-align: center;
              color: #e34d3c;
            }

            .name {
              font-size: 26px;
              line-height: 30px;
              font-family: "koho";
              color: #000000;
              white-space: nowrap;
            }
          }

          .start,
          .end {
            cursor: pointer;
            position: absolute;
            width: 34px;
            height: 34px;
            background-color: #cef2eb;
            border-radius: 100px;
            border: solid 4px #e34d3c;

            .icon {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              svg {
                display: block;
              }
            }

            .name {
              position: absolute;
              font-size: 32px;
              line-height: 32px;
              font-family: "koho";
              color: #000000;
              transition: 0.5s color 0.25s ease;
              white-space: nowrap;
            }

            .arrow {
              position: absolute;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              background-color: rgb(83, 112, 107);
              padding: 5px 15px;

              &:hover {
                &.is-start {
                  .arrow-icon {
                    top: -3px;
                  }
                }

                &.is-end {
                  .arrow-icon {
                    top: 7px;
                  }
                }
              }

              .arrow-icon {
                position: relative;
                transition: top 0.25s ease;
                max-height: 40px;

                svg {
                  width: 60px;
                  height: 40px;
                  transform: rotate(90deg);
                  top: -4px;
                  position: relative;
                }
              }

              &.is-start {
                padding-bottom: 10px;

                .arrow-icon {
                  top: 4px;

                  svg {
                    top: 0px;
                    transform: rotate(-90deg);
                  }
                }
              }

              &.is-end {
                padding-top: 10px;

                .arrow-icon {
                  top: 2px;
                }
              }

              .arrow-name {
                font-size: 16px;
                line-height: 20px;
                font-family: "koho";
                white-space: nowrap;
                color: #ffffff;
              }
            }

            .poi {
              position: absolute;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 6px;

              .poi-icon {
                width: 18px;
                height: 18px;
                background-color: #e34d3c;
                border-radius: 100%;
                flex: 1 1;
              }

              .poi-name {
                flex: 1 1;
                white-space: nowrap;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 3px;
                color: black;
                font-weight: 500;
                transition: 0.5s color 0.25s ease;
              }
            }
          }

          .start {
            .arrow {
              .arrow-icon {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }
          }

          .stop {
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #cef2eb;
            border-radius: 100%;

            .icon {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              svg {
                display: block;
                width: 100%;
                height: 100%;
              }
            }

            .name {
              position: absolute;
              white-space: nowrap;
              line-height: 18px;
              padding-bottom: 3px;
              color: #000000;
              font-weight: 500;
              left: 26px;
              top: 0px;
              transition: 0.5s color 0.25s ease;

              &.is-right {
                left: initial;
                right: 26px;
                top: -1px;
              }
            }
          }

          .pois {
            cursor: pointer;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #cef2eb;
            border-radius: 100%;

            &.hide {
              display: none;
            }

            .icon {
              display: flex;
              position: relative;
              top: -2px;
              left: -2px;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              border-radius: 100%;
              border: solid 2px #e34d3c;
            }

            .name {
              position: absolute;
              white-space: nowrap;
              line-height: 18px;
              padding-bottom: 3px;
              color: #6c565d;
              font-weight: 500;
              left: 16px;
              top: -4px;
              transition: 0.5s color 0.25s ease;

              &.is-right {
                left: initial;
                right: 17px;
                top: -5px;
              }
            }
          }
        }
      }

      .route-line {
        z-index: 1;
      }

      .map-big {
        z-index: 0;
      }
    }

    .home-container {
      padding-top: 100px;

      .headlines-container {
        max-width: 1720px;
        margin: 0 auto;
        width: 100%;
        height: calc(var(--vh) * 100 - 198px - 225px);
        position: fixed;
        left: calc((100vw - 1734px) / 2);
        overflow-x: clip;
        overflow-y: visible;
        z-index: -1;

        .main-headline {
          font-size: 70px;
          color: #87c1b4;
          background-color: #53706b;
          font-family: "koho";
          line-height: 76px;
          padding: 10px 25px;
          position: absolute;
          top: 60px;
          right: -30px;
          padding-right: 70px;
          opacity: 1;
          transition: opacity 0.5s ease, right 0.5s ease;
        }

        .sub-headline {
          font-size: 54px;
          color: #87c1b4;
          background-color: #53706b;
          font-family: "koho";
          line-height: 118px;
          padding: 10px 55px;
          position: absolute;
          top: 280px;
          right: -35px;
          padding-right: 70px;
          opacity: 1;
          transition: opacity 0.5s ease, right 0.5s ease;
        }

        .un-headline {
          background-color: white;
          font-family: "koho";
          padding: 10px 10px;
          position: absolute;
          top: 464px;
          right: -40px;
          padding-right: 70px;
          opacity: 1;
          transition: opacity 0.5s ease, right 0.5s ease;
          padding-bottom: 5px;

          > img {
            min-height: 60px;
            height: 95px;
          }
        }
      }

      .main-trailer-container-mobile {
        display: none;
        max-width: 940px;

        .main-trailer-mobile {
          .video-container {
            width: 100%;
            height: 530px;

            > iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .main-sponsoring-container,
      .main-trailer-container {
        max-width: 940px;
        margin: 60px auto;

        .main-trailer {
          .video-container {
            width: 100%;
            height: 530px;

            > iframe {
              width: 100%;
              height: 100%;
            }
          }
        }

        .main-sponsoring {
          margin-top: 60px;
          padding-bottom: 140px;
          padding-top: 10px;
          padding-left: 20px;
          background-color: white;
          border-radius: 10px;
          padding-right: 20px;

          h2 {
            text-align: center;
            text-transform: uppercase;
            font-family: KoHo;
            font-size: 32px;
            font-weight: 700;
            line-height: 41.6px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #52706b;

            &.second {
              text-transform: none;
            }
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            margin-bottom: 40px;

            a {
              color: #e34d3c;
            }
          }

          .main-sponsoring-element-container {
            display: none;

            .main-sponsoring-element-title {
              h2 {
                text-align: center;
                text-transform: none;
                font-family: KoHo;
                font-size: 32px;
                font-weight: 700;
                line-height: 41.6px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #52706b;
              }
            }

            .main-sponsoring-element-image,
            .main-sponsoring-element-image-container
              .main-sponsoring-element-image {
              border: solid 1px grey;
              border-radius: 16px;
              padding: 16px;
              position: relative;

              img {
                width: 100%;
                border-radius: 10px;
                border: solid 1px grey;
              }

              &.is-active {
                img {
                  border-radius: 0px;
                  border: none;
                }
              }

              .ribbon {
                font-size: 17px;
                font-weight: bold;
                color: #fff;

                &.is-mobile {
                  display: none;
                }
              }

              .ribbon {
                --f: 0.5em; /* control the folded part */

                position: absolute;
                top: 0;
                right: 0;
                line-height: 1.2;
                text-align: center;
                padding-inline: 2.2lh;
                padding-bottom: 12px;
                border-image: conic-gradient(#0008 0 0) 51% / var(--f);
                clip-path: polygon(
                  100% calc(100% - var(--f)),
                  100% 100%,
                  calc(100% - var(--f)) calc(100% - var(--f)),
                  var(--f) calc(100% - var(--f)),
                  0 100%,
                  0 calc(100% - var(--f)),
                  999px calc(100% - var(--f) - 999px),
                  calc(100% - 999px) calc(100% - var(--f) - 999px)
                );
                transform: translate(calc((1 - cos(45deg)) * 100%), -100%)
                  rotate(45deg);
                transform-origin: 0% 100%;
                background-color: #57657f; /* the main color  */
              }
            }
          }

          .main-sponsoring-element-container.is-gold,
          .main-sponsoring-element-container.is-silver {
            // display: flex;
            display: none;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;

            .main-sponsoring-element-image-container {
              max-width: 75%;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .main-sponsoring-element-image {
                .ribbon {
                  font-size: 13px;
                  background-color: #9a8022;
                }
              }
            }
          }

          .main-sponsoring-element-container.is-silver {
            .main-sponsoring-element-image-container {
              max-width: 60%;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .main-sponsoring-element-image {
                .ribbon {
                  font-size: 10px;
                  background-color: #626262;
                }
              }
            }
          }
        }
      }

      .content-grid {
        max-width: 940px;
        margin: 0 auto;
        margin-top: calc(var(--vh) * 100 - 198px - 225px);
        display: flex;
        gap: 20px;
        opacity: 1;

        .content-left {
          display: flex;
          flex-direction: column;
          flex: 0.6 1;
          gap: 60px;

          .content-left-top,
          .content-left-bottom,
          .content-left-bottom-2 {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 30px;
            color: #ffffff;
            display: flex;
            flex-direction: column;

            align-items: center;
          }

          .content-left-top {
            gap: 30px;

            .content-left-title {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;

              h1 {
                margin: 0 12px;
                font-family: KoHo;
                font-size: 36px;
                font-weight: 700;
                line-height: 40px;
              }

              .content-left-subtitle {
                text-align: center;

                b {
                  font-size: 24px;
                  line-height: 34px;
                }
              }
              .content-left-maintitle {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
                gap: 10px;
                margin-bottom: 8px;

                h1 {
                  margin: 0 12px;
                  font-family: KoHo;
                  font-size: 40px;
                  font-weight: 700;
                  line-height: 36px;
                  margin: 0px;
                }
              }
            }

            .content-left-info {
              width: 300px;
              display: flex;
              flex-direction: column;
              gap: 34px;
              margin: 20px 0;

              font-size: 24px;
              font-weight: 400;
              line-height: 30px;

              .content-left-info-left {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;

                > div {
                  height: 28px;
                }
              }

              .content-left-info-right {
                display: flex;
                gap: 10px;
                justify-content: flex-end;
                align-items: center;

                > div {
                  height: 28px;
                }
              }
            }
          }

          .content-left-bottom,
          .content-left-bottom-2 {
            .content-left-bottom-title {
              text-align: left;
              width: 100%;

              h2 {
                margin: 0px 0px;
                margin-bottom: 20px;
                font-family: KoHo;
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
                text-transform: uppercase;
                text-align: center;
              }
            }

            .content-left-text {
              text-align: center;
              width: 100%;
              padding: 0 15px;

              p {
                text-align: left;
                font-size: 20px;
                font-weight: 400;
                line-height: 22px;
                margin-top: 0;
              }

              h3 {
                margin: 0px 0px;
                margin-bottom: 20px;
                font-family: KoHo;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                text-transform: uppercase;
                text-align: center;
              }

              .sign-up-button {
                display: flex;
                justify-content: center;

                a {
                  display: flex;
                  justify-content: center;
                  gap: 10px;
                  align-items: center;
                  color: white;
                  background-color: rgba(235, 138, 116, 1);
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 24px;
                  width: 260px;
                  height: 45px;
                  border-radius: 30px;
                  border: none;
                  cursor: pointer;

                  .icon {
                    width: 24px;
                    height: 24px;

                    svg {
                      height: 24px;
                      width: 24px;

                      > circle,
                      > path {
                        stroke: white;
                      }
                    }
                  }
                }

                &.content {
                  margin-bottom: 20px;
                }
              }
            }

            .content-left-button {
              &.social-media {
                display: flex;
                gap: 20px;
                flex-direction: row;

                a {
                  width: 55px;
                }
              }

              a {
                display: flex;
                justify-content: center;
                gap: 10px;
                align-items: center;
                color: #000000;
                background-color: #ffffff;

                font-size: 20px;
                font-weight: 400;
                line-height: 20px;

                width: 340px;
                height: 55px;
                border-radius: 30px;
                border: none;

                cursor: pointer;

                .icon {
                  height: 28px;
                }

                .insta-icon,
                .youtube-icon,
                .facebook-icon,
                .tiktok-icon {
                  > img {
                    display: block;
                    height: 28px;
                  }
                }

                &:hover {
                  background-color: #dddddd;
                }
              }

              &.content {
                margin-bottom: 20px;
              }
            }
          }
        }

        .content-right {
          display: flex;
          flex-direction: column;
          gap: 60px;
          flex: 1;
          margin-top: 250px;

          .content-right-top,
          .content-right-middle,
          .content-right-bottom {
            .content-right-image {
              .content-right-image-circle {
                float: left;
                width: 150px;
                height: 150px;
                margin-right: 20px;
                margin-bottom: 20px;
                border: 6px solid #eb8a74;
                border-radius: 100%;
                background-size: 170px;
                background-repeat: no-repeat;

                &.meeting-line {
                  background-image: url("../assets/Meeting-bg.jpg");
                  background-position: calc(50% - 10px) calc(50% + 0px);
                }

                &.mission {
                  background-image: url("../assets/torgau_denkmal.jpg");
                  background-position: calc(50% - -6px) calc(50% + -4px);
                  background-size: 270px;
                  transform: rotate(-2deg);
                }

                &.team {
                  background-image: url("../assets/Team-bg.jpg");
                  background-position: calc(50% - 2px) -16px;
                }
              }
            }
          }

          .content-right-top {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 30px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-middle {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 30px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-bottom {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 30px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-bottom,
          .content-right-middle,
          .content-right-top {
            flex: 1;
            flex-direction: column;

            .content-right-content-title {
              h2 {
                margin: 0px;
                margin-bottom: 20px;
                font-family: KoHo;
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
                text-transform: uppercase;
              }
            }

            .content-right-content-text {
              font-size: 20px;
              font-weight: 400;
              line-height: 22px;

              p {
                margin-top: 0;
                margin-bottom: 20px;
              }
            }

            .content-right-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .content-right-content-button {
                a {
                  display: flex;
                  justify-content: center;
                  gap: 10px;
                  align-items: center;
                  color: #ffffff;
                  background-color: #86c0b4;

                  font-size: 20px;
                  font-weight: 400;
                  line-height: 20px;

                  width: 200px;
                  height: 45px;
                  border-radius: 30px;
                  border: none;

                  cursor: pointer;

                  .icon {
                    height: 28px;
                  }

                  &:hover {
                    background-color: rgb(82, 112, 107);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.is-blog {
    margin-bottom: 0px;
    margin-top: 98px;
    min-height: 1500px;
    background: none;

    .main-container {
      max-width: 100%;
      margin: 0;
      height: 100%;

      .main-image {
        img {
          width: 100%;
          display: block;
        }
      }

      .main-head {
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: 100%;
        padding: 20px 0;
        padding-top: 20px;
        background-color: #86c0b4;

        .main-head-content {
          padding-bottom: 100px;
          position: relative;
          max-width: 950px;
          margin: 0 auto;

          .main-head-line {
            background-image: url("../assets/LineTopText.svg");
            background-size: contain;
            background-repeat: no-repeat;

            position: absolute;
            width: 349px;
            height: 499px;
            bottom: -30px;
            left: -200px;
            z-index: 0;
          }

          .main-head-text-container {
            margin: 0 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            position: relative;
            z-index: 1;
            align-items: center;

            .main-head-text {
              text-align: justify;
              max-width: 950px;
              column-count: 2;
              column-gap: 20px;
              column-fill: balance;

              p {
                margin: 0;
                margin-bottom: 16px;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

                a {
                  color: #e34d3c;
                }
              }
            }

            .main-head-title {
              max-width: 950px;
              width: 100%;

              h2 {
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                font-family: KoHo;
                font-size: 32px;
                font-weight: 700;
                line-height: 38px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
            }
          }
        }
      }

      .main-container {
        max-width: 950px;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: fit-content;
        margin-bottom: 80px;
        padding-top: 238px;
        padding-bottom: 100px;

        .main-content-title {
          position: relative;
          z-index: 2;
          height: 100px;
          h1 {
            text-align: left;
            text-transform: uppercase;
            font-family: KoHo;
            font-size: 32px;
            font-weight: 700;
            line-height: 41.6px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #52706b;
            margin-left: 470px;
          }
        }

        .main-content-text {
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          gap: 40px;
          max-width: 710px;
          margin: 0;
          margin-left: 180px;

          .team-member-item-container,
          .sponsor-badge-item-container {
            display: flex;
            gap: 40px;
            flex-direction: row;
            align-items: flex-start;

            .team-member-item-image,
            .sponsor-badge-item-image {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 250px;

              img {
                width: 250px;
                border-radius: 100%;
                border: 6px solid #eb8a74;
                height: 250px;
              }
            }

            .sponsor-badge-item-image {
              img {
                border: none;
              }
            }

            .team-member-item-text-container,
            .sponsor-badge-item-text-container {
              flex: 1 1;
              padding-top: 0px;

              .team-member-item-text-title,
              .sponsor-badge-item-text-title {
                h3 {
                  font-family: KoHo;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 26px;
                  text-align: left;
                  text-decoration-skip-ink: none;
                }
              }

              .sponsor-badge-item-text-text {
                &.is-bold {
                  font-weight: bold;
                }
              }

              .sponsor-badge-item-text-list {
                a {
                  color: #e34d3c;
                }
              }

              .sponsor-badge-item-partner-list {
                display: flex;
                max-width: 450px;
                justify-content: center;
                margin: 10px 0;
                gap: 10px;
                flex-wrap: wrap;

                .sponsor-badge-item-partner-list-item {
                  border-radius: 10px;
                  border: solid 1px grey;
                  overflow: hidden;
                  box-sizing: border-box;
                  flex: 1 1 100%;
                  max-width: 100%;

                  &.is-active {
                    border: none;
                  }
                  &.is-gold {
                    flex: 1 1 48%;
                    max-width: 48%;
                  }

                  &.is-silver {
                    flex: 1 1 31%;
                    max-width: 31%;
                  }

                  img {
                    width: 100%;
                    display: block;
                  }
                }
              }
            }
          }

          .team-member-item-container:nth-child(even),
          .sponsor-badge-item-container:nth-child(even) {
            flex-direction: row-reverse;
          }
        }

        .main-content-line {
          background-image: url("../assets/LineContentText.svg");

          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
          top: -635px;
          left: 83px;
          height: 1680px;
          width: 909px;
        }

        .main-content-line-2 {
          background-image: url("../assets/LineContentText.svg");
          background-position-y: -1055px;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
          top: 1045px;
          left: 83px;
          height: 2256px;
          width: 909px;
        }

        > div > p:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.main.is-blog {
  .main-container {
    .Sponsors-main {
      .main-container {
        padding-top: 100px;

        .main-content-title {
          h1 {
            margin-left: 440px;
          }
        }
        .main-content-text {
          max-width: 775px;
          margin-left: 150px;
          gap: 65px;
        }
      }
    }
  }
}

.footer {
  z-index: 2;
  position: relative;
  top: -120px;
}

.footer-container {
  height: 830px;
  position: relative;
  overflow: hidden;

  .upper-circle {
    position: absolute;
    background-color: #eb8a74;
    width: 8500px;
    height: 11000px;
    left: calc(-4250px + 50%);
    border-radius: 100%;
    border: 28px solid #e34d3c;

    .contact-content {
      position: relative;
      display: flex;
      max-width: 830px;
      margin: 0 auto;
      top: 20px;
      color: white;
      text-align: center;
      padding-top: 20px;
      justify-content: space-between;
      align-items: center;

      .logo-container {
        max-width: 310px;
        position: relative;

        &.big-logo {
          top: -50px;
          svg {
            width: 100%;
          }
        }
      }

      .line-container {
        position: absolute;
        top: 0px;
        left: 420px;
      }

      .form-content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        align-items: flex-end;
        margin-bottom: 100px;

        .form-title {
          font-family: KoHo;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;

          h3 {
            margin: 0;
          }
        }

        .form-container {
          background-color: #ffffff;
          width: 500px;
          min-height: 300px;
          filter: drop-shadow(-10px 10px 0px #e34d3c);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .lower-circle {
    position: absolute;
    background-color: #e34d3c;
    width: 8500px;
    height: 11000px;
    left: calc(-4250px + 50%);
    top: 680px;
    border-radius: 100%;

    .partners-content {
      position: relative;
      max-width: 660px;
      margin: 0 auto;
      top: 20px;
      color: white;
      text-align: center;

      h2 {
        font-family: KoHo;
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1733px) {
  .info-container {
    width: calc(50vw - 45px) !important;
  }
}

@media only screen and (max-width: 1024px) {
  .info-container {
    width: calc(100vw - 45px) !important;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1733px) {
  .Vision-main {
    max-height: 2350px;
  }

  .back-container {
    left: calc(50vw - 90px) !important;
    &.open {
      display: none !important;
    }
  }

  .options-container {
    &.open {
      display: none !important;
    }
  }

  .headlines-container {
    left: unset !important;
    right: 0;

    .main-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 4.04859vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 4.386vw) !important;
      top: calc((1733px - 100vw) * 0.1 + 3.465vw) !important;
    }
    .sub-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 3.116vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 6.9vw) !important;
      top: calc((1733px - 100vw) * 0.12 + 16.159vw) !important;
    }
    .un-headline {
      top: calc((1733px - 100vw) * 0.14 + 26.78vw) !important;
      > img {
        height: calc((1733px - 100vw) * 0.008 + 5.485vw) !important;
      }
    }
  }
}

@media only screen and (max-width: 1107px) {
  .Vision-main {
    max-height: 2350px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 950px) {
  .slideshow-area {
    transform: scale(1.3) !important;
  }

  .headlines-container {
    left: unset !important;
    right: 0;

    .main-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 4.04859vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 4.386vw) !important;
      top: calc((1733px - 100vw) * 0.06 + 0.465vw) !important;
    }
    .sub-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 3.116vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 6.9vw) !important;
      top: calc((1733px - 100vw) * 0.09 + 13.159vw) !important;
    }
    .un-headline {
      top: calc((1733px - 100vw) * 0.12 + 23.78vw) !important;
      > img {
        min-height: 55px !important;
        height: calc((1733px - 100vw) * 0.008 + 5.485vw) !important;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .App {
    &.show-info {
      overflow: hidden;
      max-height: 100vh;

      header {
        position: relative;
      }

      main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        padding-top: 0;
        margin-top: 0;

        .main-container {
          .tour-route-container {
            height: calc(var(--vh) * 100);
          }
        }
      }
    }
  }
  .main-trailer-container {
    display: none;
  }

  .info-etappe-container {
    width: unset !important;
  }

  .info-etappe-image-slider {
    height: 32vh !important;
    > div {
      max-height: 25vh !important;
    }
  }

  .poi-item {
    width: unset !important;
  }
  .info-etappe-poi-text {
    padding-left: 15px !important;
    padding-right: 15px !important;
    text-align: justify;
  }

  .options-container,
  .back-container {
    &.open {
      display: none !important;
    }
  }

  .overlay-layer {
    left: 600vw !important;

    &.open {
      left: -200vw !important;
    }
  }

  .info-etappe-container {
    margin-top: 2px !important;
  }

  .main-trailer-container-mobile {
    display: contents !important;
    margin: 0 auto !important;
  }

  .content-right-content-title {
    h2 {
      text-align: center !important;
    }
  }

  .designed-by {
    min-height: 86px;
  }

  .Impressum-main,
  .Partners-main,
  .DataSecurity-main,
  .Join-main,
  .Newsletter-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Newsletter-main {
    text-align: center;
  }

  .info-container {
    width: 95vw !important;
    max-width: 95vw !important;

    &.visible {
      right: calc((100vw - (100vw - 30px)) / 2) !important;
    }

    .info-scroll-container {
      margin-top: 25px;
      padding-bottom: 25px;
    }
  }

  .Vision-main {
    max-height: 1550px;

    .slideshow-area {
      .slideshow-container {
        margin-top: 0px !important;
      }

      .slideshow-label-container {
        width: 60% !important;
      }
    }

    .main-content-line {
      top: 397px !important;
    }

    .main-content-line-2 {
      top: 894px !important;
    }

    .main-content-line-3 {
      top: 1290px !important;
    }

    .main-content-circle {
      top: 600px;
      height: 330px;
      width: 330px;
      border-width: 15px;

      .main-content-circle-text {
        font-size: 16px;
        line-height: 1.7;
      }
    }

    .main-content-circle-2 {
      top: 1055px;
      left: calc(50% - 0px);
      height: 330px;
      width: 330px;
      border-width: 15px;

      .main-content-circle-text {
        font-size: 15px;
        padding-top: 10px;
        line-height: 1.4;
      }

      > img {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .main-content-text-right {
      top: 1460px;
      left: calc(50% - 400px);
      width: 400px;

      .main-content-text-right-text {
        font-size: 20px;
        padding-top: 10px;
        line-height: 24px;
      }
      .main-content-text-right-subtext {
        font-size: 20px;
        display: block;
      }
    }

    .main-content-line {
      background-size: 490px;
      top: 347px;
      height: 500px;
      max-width: 500px;
    }

    .main-content-line-2 {
      background-size: 135px;
      top: 844px;
      left: calc(50% + 0px);
      height: 500px;
      max-width: 500px;
    }

    .main-content-line-3 {
      background-size: 300px;
      top: 1240px;
      left: calc(50% + 200px);
      height: 500px;
      max-width: 500px;
    }

    .main-image {
      margin-top: 100px;
    }

    .main-image-map {
      margin-top: 400px;
    }
  }

  .header {
    position: fixed;
    width: 100vw;
    height: 200px;

    &.smaller {
      height: 120px;

      .menu-container {
        height: 120px;
      }

      .logo-container {
        // top: -16px;
        max-height: 70px !important;

        .injected-svg {
          width: 52px;
          height: 52px;
        }
      }
    }

    .header-container {
      align-items: center;
      max-width: 100%;
      height: 100%;
      padding: 0;
      flex-direction: column;

      .logo-container {
        max-width: 120px;
        max-height: 120px;

        svg {
          width: 120px;
          height: 120px;
        }
      }

      .slogan {
        position: absolute;
        top: 45px;
        right: 10px;
        padding: 4px 10px;
        font-size: 15px;

        .head {
          margin: 0;
          font-size: 18px;
        }
      }

      .menu-container {
        height: 50px;
        margin: 0 20px;
        gap: 20px;

        .menu-item {
          font-size: 18px;
        }
      }
    }
  }

  .footer-container {
    max-width: 100%;
  }

  .main {
    margin-top: 0px;
    padding-top: 200px;
    background-attachment: fixed;
    min-height: 1600px;

    &.is-home {
      background-position-y: -142px;
      background-position-x: calc(50% + -75px);
    }

    .route-container {
      &.is-home {
        background-attachment: fixed;
        background-position-y: 145px;
        background-position-x: calc(50% + -75px);
        background-size: 444px;
      }

      &.is-blog {
        background: none;
      }
    }

    &.is-tour {
      padding: 0 0px;
      padding-top: 100px;
    }

    &.is-home {
      background-image: url("../assets/map-background-long.png");
      padding: 0;
      padding-top: 102px;
      background-size: 1166px;

      .main-container {
        padding-right: 0px;
        padding-left: 0px;
      }
    }

    &.is-blog {
      padding: 0;
      padding-top: 102px;
      background: none;

      .main-container {
        padding-right: 0px;
        padding-left: 0px;

        .main-container {
          padding-bottom: 25px;

          .main-content-title {
            margin: 0 20px;
          }
          .main-content-text {
            .sponsor-badge-item-container {
              margin: 0 20px;
              flex-direction: column !important;
              align-items: center;
              gap: 20px;
              text-align: center;

              .sponsor-badge-item-text-title {
                display: flex;
                justify-content: center;
                h3 {
                  text-align: center !important;
                  max-width: 300px;
                }
              }
            }
          }
        }
      }
    }

    .main-container {
      .tour-route-container {
        height: calc(var(--vh) * 100 - 255px);

        .map-big {
          > div:first-child,
          > div:nth-child(3) {
            svg {
              height: calc(var(--vh) * 100 - 255px);
            }
          }

          .etappe-0,
          .etappe-1,
          .etappe-2,
          .etappe-3,
          .etappe-4,
          .etappe-5,
          .etappe-6,
          .etappe-7,
          .etappe-8,
          .etappe-9,
          .etappe-10,
          .etappe-11,
          .etappe-12,
          .etappe-13,
          .etappe-14,
          .etappe-15,
          .etappe-16,
          .etappe-17 {
            height: calc(var(--vh) * 100 - 255px);
          }
        }
      }
      .home-container {
        padding: 20px 0px;

        .content-grid {
          flex-direction: column;
          margin-left: 20px;
          margin-right: 20px;

          .content-left {
            gap: 30px;
            .content-left-text {
              padding: 0px;
            }
          }

          .content-right {
            margin-top: 10px;
            gap: 30px;
          }
        }

        .main-sponsoring-container {
          margin-left: 20px;
          margin-right: 20px;
          border-radius: 10px;
          background-color: #ffffff;
          padding: 20px;
          margin-top: 60px;
          margin-bottom: 60px;

          .main-sponsoring {
            margin-top: 10px;
            padding-bottom: 40px;
          }

          .ribbon {
            display: none;
          }

          .ribbon.is-mobile {
            display: inherit !important;
            font-size: 10px !important;
            line-height: 1.1 !important;
            padding-inline: 1.5lh !important;
            padding-bottom: 8px !important;

            &.is-gold {
              font-size: 8.5px !important;
            }

            &.is-silver {
              font-size: 7px !important;
            }
          }
        }
      }

      .container {
        .Team-main {
          .main-container {
            overflow: hidden !important;
          }
        }

        .Team-main,
        .Sponsors-main {
          .main-head {
            .main-head-text-container {
              .main-head-text {
                column-count: 1;
              }
            }
          }
          .main-container {
            padding-top: 120px;
            height: fit-content;
            margin-bottom: 60px;

            .main-content-title {
              h1 {
                margin-left: 0px;
                text-align: center;
              }
            }
            .main-content-text {
              margin-left: 0px;
              max-width: 100%;
              gap: 80px;

              .team-member-item-container,
              .sponsor-bagde-item-container {
                flex-direction: column;
                align-items: center;
                gap: 0px;

                .team-member-item-text-container,
                .sponsor-bagde-item-text-container {
                  padding: 0 20px;
                  text-align: center;
                  max-width: 500px;

                  .team-member-item-text-title,
                  .sponsor-bagde-item-text-title {
                    h3 {
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .content-right-image {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .main-container {
      max-width: 100%;
      margin: 0;
    }
  }

  .footer {
    top: -30px;

    .footer-container {
      height: 1065px;

      .lower-circle {
        top: 880px;

        .partners-content {
          max-width: calc(100vw - 50px);
        }
      }
      .upper-circle {
        .contact-content {
          max-width: calc(100vw - 50px);
          left: -25px;
          flex-direction: column;

          .form-content {
            width: 100%;
            .form-container {
              width: 100%;
              min-height: 375px;
            }
          }
          .line-container {
            left: 150px;
            transform: scale(1.6);
            top: 170px;
          }
          .logo-container {
            z-index: 1;
            max-height: 300px;
            margin-bottom: 35px;

            &.big-logo {
              top: 0px;
            }

            svg {
              height: 300px;
            }
          }
        }
      }
    }
  }

  .App {
    .partner-container {
      bottom: -120px;
      height: 150px;

      .partners {
        gap: 20px;

        .copyright-container {
          padding: 0 20px;
        }

        .partner-logos-container {
          gap: 20px;
          justify-content: center;

          &.small {
            gap: 20px;

            .partner-logo-container {
              height: 35px;

              .partner-logo {
                height: 100%;
              }
            }
          }

          &.mobile {
            display: flex;
          }
          &.desktop {
            display: none;
          }

          .partner-logo-container {
            height: 35px;

            .partner-logo {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .slideshow-area {
    transform: scale(1) !important;
  }

  .headlines-container {
    left: unset !important;
    right: 0;

    .main-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 4.04859vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 4.386vw) !important;
      top: calc((1733px - 100vw) * 0.06 + 0.465vw) !important;
    }
    .sub-headline {
      font-size: calc((1733px - 100vw) * 0.008 + 3.116vw) !important;
      line-height: calc((1733px - 100vw) * 0.008 + 6.9vw) !important;
      top: calc((1733px - 100vw) * 0.09 + 13.159vw) !important;
    }
    .un-headline {
      top: calc((1733px - 100vw) * 0.12 + 23.78vw) !important;
      > img {
        min-height: 50px !important;
        height: calc((1733px - 100vw) * 0.008 + 5.485vw) !important;
      }
    }
  }
}
