/* #### Generated By: http://font.download #### */

@font-face {
  font-family: "KoHo Regular";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Regular"), url("../fonts/KoHo-Regular.woff") format("woff");
}

@font-face {
  font-family: "KoHo Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Italic"), url("../fonts/KoHo-Italic.woff") format("woff");
}

@font-face {
  font-family: "KoHo Bold";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Bold"), url("../fonts/KoHo-Bold.woff") format("woff");
}

@font-face {
  font-family: "KoHo Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Bold Italic"),
    url("../fonts/KoHo-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "KoHo ExtraLight Regular";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo ExtraLight Regular"),
    url("../fonts/KoHo-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "KoHo Light Regular";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Light Regular"),
    url("../fonts/KoHo-Light.woff") format("woff");
}

@font-face {
  font-family: "KoHo Medium Regular";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Medium Regular"),
    url("../fonts/KoHo-Medium.woff") format("woff");
}

@font-face {
  font-family: "KoHo SemiBold Regular";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo SemiBold Regular"),
    url("../fonts/KoHo-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "KoHo ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo ExtraLight Italic"),
    url("../fonts/KoHo-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "KoHo Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Light Italic"),
    url("../fonts/KoHo-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "KoHo Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo Medium Italic"),
    url("../fonts/KoHo-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "KoHo SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("KoHo SemiBold Italic"),
    url("../fonts/KoHo-SemiBoldItalic.woff") format("woff");
}
