.slideshow-container {
  position: relative;
  width: 555px;
  height: 323px;
  overflow: visible;
  border: 20px solid rgba(82, 112, 108, 1);
  border-radius: 10px;
  box-shadow: -10px 10px 0px rgba(134, 192, 180, 1);
}

.slideshow-label-container {
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 850px;
  text-align: center;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.slide {
  position: absolute;
  top: 0;
  display: block;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label.active {
  opacity: 1;
}

.slide.active {
  opacity: 1;
}

.controls {
  transform: scale(2);
  transform-origin: right bottom;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;

  svg {
    cursor: pointer;
  }
}

.progress-bar-container {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -22px;

  .progress-bar {
    height: 100%;
    background-color: #e34d3c;
  }
}

.arrows {
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
    position: relative;

    .ardennen {
      top: 160px;
      left: 90px;
      position: absolute;
      width: 48px;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .westfront-arrow-left {
      top: 210px;
      left: 13px;
      position: absolute;
      width: 80px;
      transform: scaleY(-1) rotate(83deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right {
      top: 100px;
      right: 20px;
      position: absolute;
      width: 70px;
      transform: scaleX(-1) scaleY(1);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right-2 {
      top: 100px;
      right: 100px;
      position: absolute;
      width: 95px;
      transform: scaleX(-1) scaleY(1);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right-3 {
      top: 85px;
      right: 120px;
      position: absolute;
      width: 120px;
      transform: scaleX(-1) scaleY(1);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .westfront-arrow-left-2 {
      top: 145px;
      left: 55px;
      position: absolute;
      width: 80px;
      transform: scaleX(1) scaleY(1);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .westfront-arrow-left-3 {
      top: 125px;
      left: 105px;
      position: absolute;
      width: 120px;
      transform: scaleX(1) scaleY(1) rotate(-10deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right-4 {
      top: 111px;
      right: 220px;
      position: absolute;
      width: 40px;
      transform: scaleX(-1) scaleY(1) rotate(39deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right-5 {
      top: 90px;
      right: 235px;
      position: absolute;
      width: 30px;
      transform: scaleX(-1) scaleY(-1) rotate(56deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .eastfront-arrow-right-6 {
      top: 70px;
      right: 180px;
      position: absolute;
      width: 100px;
      transform: scaleX(-1) scaleY(-1) rotate(-15deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    .westfront-arrow-left-4 {
      top: 150px;
      left: 140px;
      position: absolute;
      width: 100px;
      transform: scaleX(1) scaleY(-1) rotate(15deg);
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .slideshow-container {
    width: 455px;
    height: 265px;
    max-width: 100%;
    max-height: calc(50vw);
    margin-top: 24px;
    transform: scale(0.9);
  }

  .slideshow-label-container {
    width: 100%;
    margin-bottom: 48px;

    .label {
      top: -10px;
    }
  }

  .arrows {
    > div {
      .ardennen {
        top: 140px;
        left: 75px;
        width: 38px;
      }

      .westfront-arrow-left {
        top: 185px;
        left: 10px;
        width: 70px;
        transform: scaleY(-1) rotate(90deg);
      }

      .eastfront-arrow-right {
        top: 80px;
        right: 20px;
        width: 60px;
      }

      .eastfront-arrow-right-2 {
        top: 80px;
        right: 55px;
        width: 85px;
      }

      .eastfront-arrow-right-3 {
        top: 65px;
        right: 75px;
        width: 110px;
      }

      .westfront-arrow-left-2 {
        top: 120px;
        left: 45px;
        width: 70px;
      }

      .westfront-arrow-left-3 {
        top: 105px;
        left: 85px;
        width: 100px;
      }

      .eastfront-arrow-right-4 {
        top: 92px;
        right: 188px;
        width: 30px;
      }

      .eastfront-arrow-right-5 {
        top: 75px;
        right: 195px;
        width: 20px;
      }

      .eastfront-arrow-right-6 {
        top: 60px;
        right: 150px;
        width: 80px;
      }

      .westfront-arrow-left-4 {
        top: 140px;
        left: 130px;
        width: 80px;
      }
    }
  }
}

@media only screen and (max-width: 532px) {
  .arrows {
    > div {
      .ardennen {
        top: 26.3vw;
        left: 14.1vw;
        width: 7.1vw;
      }

      .westfront-arrow-left {
        top: 34.8vw;
        left: 1.9vw;
        width: 13.2vw;
      }

      .eastfront-arrow-right {
        top: 15vw;
        right: 3.8vw;
        width: 11.2vw;
      }

      .eastfront-arrow-right-2 {
        top: 15vw;
        right: 10.3vw;
        width: 16vw;
      }

      .eastfront-arrow-right-3 {
        top: 12.2vw;
        right: 14.1vw;
        width: 20.7vw;
      }

      .westfront-arrow-left-2 {
        top: 22.6vw;
        left: 8.5vw;
        width: 13.1vw;
      }

      .westfront-arrow-left-3 {
        top: 19.7vw;
        left: 16vw;
        width: 18.9vw;
      }

      .eastfront-arrow-right-4 {
        top: 17.3vw;
        right: 35.4vw;
        width: 5.7vw;
      }

      .eastfront-arrow-right-5 {
        top: 14.1vw;
        right: 36.6vw;
        width: 3.9vw;
      }

      .eastfront-arrow-right-6 {
        top: 11.3vw;
        right: 28.2vw;
        width: 14.9vw;
      }

      .westfront-arrow-left-4 {
        top: 26.3vw;
        left: 24.4vw;
        width: 15vw;
      }
    }
  }
}
